import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Notification.scss';
import { useTranslation } from 'react-i18next';
import getCatalogLabel from '../../../components/QuotationForm/components/tripDetails.mapper';

export interface NotificationProps {
  open: boolean;
  message: string;
  skipTranslation: boolean;
  severity: 'success' | 'warning' | 'error';
  translationArg?: string;
  onClose: () => void;
}

export const Notification: React.FC<NotificationProps> = ({
  open,
  message,
  severity,
  skipTranslation,
  translationArg,
  onClose,
}) => {
  const { t } = useTranslation();
  const handleSnackbarClose = (): void => {
    onClose();
  };

  return (
    <Snackbar
      className="notification"
      open={open}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleSnackbarClose}
        severity={severity}
      >
        {skipTranslation
          ? message
          : t(`NOTIFICATION.${severity.toUpperCase()}.${message}`, {
              translationArg,
            })}
      </MuiAlert>
    </Snackbar>
  );
};

Notification.defaultProps = {
  translationArg: '',
};
