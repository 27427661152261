import {
  GtmEvent,
  GTMEventTypes,
  InteractionTypes,
} from '../../interfaces/gtm.interface';
import ErrorLoggingService from '../errorlogging.service';
import { BookingStepsContextState } from '../../../context/bookingContext/bookingContext.types';
import { Policy } from '../../interfaces/policyResponse.interface';
import { ITransactionResponse } from '../../../components/shared/braintree/payments';
import preparePageViewEventPayload from './preparePageViewEventPayload';
import prepareTransactionEventPayload from './prepareTransactionEventPayload';
import { BookingConfig } from '../../../components/BookingWidget/bookingSteps.interface';
import { TotalPrice } from '../../../context/priceSummaryContext/priceSummaryContext';

const pushGtmEvent = (event: GtmEvent): void => {
  if (window.parent) {
    window.parent.postMessage(
      {
        type: 'GTM_EVENT',
        data: event,
      },
      '*',
    );
  }
};

export const sendTransactionEvent = ({
  transactionResponse,
  bookingContext,
  policy,
  bookingConfig,
  businessUnit,
}: {
  transactionResponse: ITransactionResponse;
  bookingContext: BookingStepsContextState;
  policy: Policy;
  bookingConfig: BookingConfig;
  businessUnit: string;
}): void => {
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();

  try {
    const event: GtmEvent = {
      event: GTMEventTypes.TrackTransaction,
      ...prepareTransactionEventPayload({
        transactionResponse,
        bookingContext,
        policy,
        bookingConfig,
        businessUnit,
      }),
    };
    pushGtmEvent(event);
  } catch (error) {
    errorService.log('Error sending GTM event to parent', error);
  }
};

export const sendInteractionEvent = (
  interactionType: InteractionTypes,
  label: string,
): void => {
  pushGtmEvent({
    event: GTMEventTypes.TrackInteraction,
    interactionValue: interactionType,
    interactionLabel: label,
  });
};

const pageNameMap: Record<string, string> = {
  quotationForm: 'Quote',
  quotationProposals: 'Plans',
  excessAndAddons: 'Excess and Addons',
  quotationInformationForm: 'Travellers',
  quotationRecapDeclarations: 'Payment',
  quotationConfirmation: 'Confirmation',
};

export const sendGTMEventPageChange = ({
  stepName,
  bookingContext,
  bookingConfig,
  totalPrice,
  businessUnit,
}: {
  stepName: string;
  bookingContext: BookingStepsContextState;
  bookingConfig: BookingConfig;
  totalPrice: TotalPrice | null;
  businessUnit: string;
}): void => {
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  try {
    pushGtmEvent({
      event: GTMEventTypes.TrackPageview,
      fullPageUrl: window.location.href,
      pageType: 'Insurance Whitelabel',
      pageName: pageNameMap[stepName] ?? stepName,
      ...preparePageViewEventPayload({
        bookingSteps: bookingContext,
        bookingConfig,
        totalPrice,
        businessUnit,
      }),
    });
  } catch (error) {
    errorService.log('Error sending GTM event to parent', error);
  }
};
