import { differenceInYears } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import {
  Companion,
  CompanionEnum,
  Customer,
  CustomerEnum,
} from '../BookingWidget/BookingManagment/bookingManagment.interface';
import {
  FormQuotationInformationEnum,
  FormQuotationInformationPersonalData,
  FormQuotationInformationTravellerList,
} from './quoation-information.interface';
import { BookingSubStepsQuotationForm } from '../../context/bookingContext/bookingContext.types';
import { parseDateTimeStringToDate } from '../../shared/helpers/date.helper';

const FIRST_VALID_FULL_DATE = dayjs('01-01-1000');

const prepareInitInformationPersonal = (
  customer: Customer,
): FormQuotationInformationPersonalData => ({
  [FormQuotationInformationEnum.Title]: customer[CustomerEnum.Title] as string,
  [FormQuotationInformationEnum.Firstname]: customer[
    CustomerEnum.FirstName
  ] as string,
  [FormQuotationInformationEnum.Lastname]: customer[
    CustomerEnum.LastName
  ] as string,
  [FormQuotationInformationEnum.Email]: customer[
    CustomerEnum.EmailAddress
  ] as string,
  [FormQuotationInformationEnum.ReenterEmail]:
    customer[CustomerEnum.EmailAddress],
  [FormQuotationInformationEnum.Phone]: customer[
    CustomerEnum.PhoneNumber1
  ] as string,
  [FormQuotationInformationEnum.State]: customer[
    CustomerEnum.AddressLine4
  ] as string,
  [FormQuotationInformationEnum.Birthdate]: parseDateTimeStringToDate(
    customer[CustomerEnum.DateOfBirth],
  ),
});

const prepareInitInformationTravellers = (
  companions: Companion[],
): FormQuotationInformationTravellerList => {
  const travellers: FormQuotationInformationTravellerList = {};

  // eslint-disable-next-line array-callback-return
  companions.map((companion, index) => {
    const indexForPS = (index + 1).toString();
    travellers[
      `travellerFirstName${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = companion[CompanionEnum.FirstName];
    travellers[
      `travellerLastName${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = companion[CompanionEnum.LastName];
    travellers[
      `travellerAge${indexForPS}` as keyof FormQuotationInformationTravellerList
    ] = differenceInYears(
      new Date(),
      new Date(companion[CompanionEnum.DateOfBirth]),
    ).toString();
  });

  return travellers;
};

const getPolicyWordingName = (brand: string): string => {
  if (brand.toLowerCase().includes('nz')) {
    return 'Policy Wording';
  }
  return 'PDS';
};

const normalizeDate = (date: string): string =>
  dayjs(date).format('YYYY-MM-DD');

// Helper function to check deep equality of objects or arrays
const isEqual = (objA: any, objB: any): boolean => {
  if (typeof objA !== typeof objB) return false;

  if (typeof objA === 'object' && objA !== null && objB !== null) {
    if (Array.isArray(objA) && Array.isArray(objB)) {
      if (objA.length !== objB.length) return false;
      return objA.every((item, index) => isEqual(item, objB[index]));
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);
    const sharedKeys = keysA.filter((key) => keysB.includes(key));

    return sharedKeys.every((key) => {
      if (key === 'departureDate' || key === 'returnDate') {
        return normalizeDate(objA[key]) === normalizeDate(objB[key]);
      }
      return isEqual(objA[key], objB[key]);
    });
  }

  return objA === objB;
};

const isTravelCriteriaEqual = (
  quoteA: BookingSubStepsQuotationForm,
  quoteB: BookingSubStepsQuotationForm,
): boolean => {
  const fieldsToCompare = {
    departureDate: quoteA.departureDate,
    geographicalZone: quoteA.geographicalZone,
    returnDate: quoteA.returnDate,
    travellersAge: quoteA.travellersAge,
    tripDetails: quoteA.tripDetails,
  };

  const fieldsToCompareB = {
    departureDate: quoteB.departureDate,
    geographicalZone: quoteB.geographicalZone,
    returnDate: quoteB.returnDate,
    travellersAge: quoteB.travellersAge,
    tripDetails: quoteB.tripDetails,
  };

  return isEqual(fieldsToCompare, fieldsToCompareB);
};

const isDOBbeforeToday = (value: Dayjs): boolean =>
  value?.isValid() && value.utc(true).isBefore(dayjs.utc(), 'day');

const checkIfBirthDateValid = (value: Dayjs): boolean =>
  value?.isValid() && value.isAfter(FIRST_VALID_FULL_DATE);

export {
  prepareInitInformationPersonal,
  prepareInitInformationTravellers,
  getPolicyWordingName,
  checkIfBirthDateValid,
  isDOBbeforeToday,
  isTravelCriteriaEqual,
};
