import React from 'react';
import { t } from 'i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

interface DeleteDialogProps {
  open: boolean;
  title: string;
  description: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  title,
  description,
  onConfirm,
  onCancel,
}) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="error">
        {t('MODAL_DIALOG.delete.discard')}
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        {t('MODAL_DIALOG.delete.confirm')}
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
