import {
  BookingConfig,
  BookingConfigEnum,
  ConfigTypeEnum,
} from '../../../components/BookingWidget/bookingSteps.interface';
import { ITransactionResponse } from '../../../components/shared/braintree/payments';
import { BookingStepsContextState } from '../../../context/bookingContext/bookingContext.types';
import { TransactionEventPayload } from '../../interfaces/gtm.interface';
import { Policy } from '../../interfaces/policyResponse.interface';

const paymentTypeNames: Record<string, string> = {
  credit_card: 'CREDITCARD',
};

export default function prepareTransactionEventPayload({
  transactionResponse,
  bookingContext,
  policy,
  bookingConfig,
  businessUnit,
}: {
  transactionResponse: ITransactionResponse;
  bookingContext: BookingStepsContextState;
  policy: Policy;
  bookingConfig: BookingConfig;
  businessUnit: string;
}): TransactionEventPayload {
  const { policyNumber, additionalValues, price } = policy;
  const { phone, email } =
    bookingContext.quotationInformationForm.informationPersonal;
  const { transactionId, paymentMethod } = transactionResponse;
  return {
    transactionId,
    country: bookingConfig[BookingConfigEnum.Country]?.country || '',
    brand: bookingConfig[BookingConfigEnum.BrandCode] || '',
    store:
      bookingConfig[BookingConfigEnum.Type] === ConfigTypeEnum.WHITE_LABEL
        ? 'WL'
        : businessUnit || '',
    transactionTotal: price.grossPremium,
    transactionTax: 0,
    transactionAffiliation: bookingConfig[BookingConfigEnum.BrandCode] || '',
    transactionProducts: [
      {
        sku: policyNumber,
        name: additionalValues[0]?.data.productName ?? '',
        quantity: 1,
        category: 'insurance',
        price: price.grossPremium,
      },
    ],
    merchantFeeTotal: null,
    paymentMethod:
      paymentTypeNames[paymentMethod] ?? paymentMethod?.toUpperCase(),
    primaryPhone: phone,
    primaryEmailAddress: email,
  };
}
