import React, {
  FC,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { differenceInYears } from 'date-fns';
import { AxiosResponse } from 'axios';
import './BookingWidget.scss';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import i18n from 'i18next';
import useFetchBookingConfig from './BookingConfig/useFetchBookingConf';
import {
  Banner,
  BannerEnum,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
  BookingStepIndex,
} from './bookingSteps.interface';
import { LoadingContext } from '../../context/loadingContext/loadingContext';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import { BookingContext } from '../../context/bookingContext/bookingContext';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import useApi from '../../shared/services/api.service';
import {
  BookingDataEnum,
  BookingManagementEnum,
  Companion,
  CompanionEnum,
  Customer,
  CustomerEnum,
} from './BookingManagment/bookingManagment.interface';
import {
  BookingMeResponse,
  BookingMeResponseEnum,
} from './bookingMe.interface';
import {
  prepareInitInformationPersonal,
  prepareInitInformationTravellers,
} from '../QuotationInformationForm/quotation-information.helper';
import { isAgent, isAuthCustomer } from '../../shared/helpers/auth.helper';
import ErrorLoggingService from '../../shared/services/errorlogging.service';
import { AppConfigurationContext } from '../../context/appConfigurationContext/appConfigurationContext';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import useSummaryVisible from '../../shared/helpers/useSummaryVisible';
import { sendGTMEventPageChange } from '../../shared/services/gtm/gtm.service';

import { PanelRightVisibleForComponent } from '../../shared/consts/app.const';
import SpinnerStatic from '../../shared/components/Loading/SpinnerStatic';
import useBookingResume from './helpers/useBookingResume';
import { StepperContext } from '../../context/stepperContext/stepperContext';
import ProgressStepper from '../shared/ProgressStepper';
import { CacheDataContext } from '../../context/cacheContext/cacheContext';
import ProgressStepperMobile from '../shared/progress-stepper-mobile/progress-stepper-mobile';
import QuotationSummaryMobileBackdrop from '../QuotationSummaryMobile/QuotationSummaryMobileBackdrop';
import OpenQuoteSummary from '../QuotationSummaryMobile/OpenQuoteSummary';
import { AppEvents, subscribe$, unsubscribe$ } from '../../shared/app-events';
import isWhitelabel from '../../shared/helpers/whitelabel';
import WhiteLabelTitle from './WhitelabelTitile/WhitelabelTitle';
import { usePriceSummaryLoading } from '../../context/priceSummaryContext/priceSummaryContext';
import { useSessionContext } from '../../context/sessionContext/sessionContext';
import { BookingDataContext } from '../../context/bookingDataContext/bookingDataContext';

const PanelRight = React.lazy(() => import('../PanelRight/PanelRight'));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BookingWidgetProps {}

const getComponent = async (componentName: string): Promise<any> => {
  switch (componentName) {
    case 'QuotationPreliminaryDeclarations':
      return import(
        '../QuotationPreliminaryDeclarations/QuotationPreliminaryDeclarations'
      );
    case 'QuotationForm':
      return import('../QuotationForm/QuotationForm');
    case 'QuotationFormWeb':
      return import('../QuotationFormWeb/QuotationFormWeb');
    case 'QuotationConfirmation':
      return import('../QuotationConfirmation/QuotationConfirmation');
    case 'QuotationInformationForm':
      return import('../QuotationInformationForm/QuotationInformationForm');
    case 'QuotationMedicalScreening':
      return import('../QuotationMedicalScreening/QuotationMedicalScreening');
    case 'QuotationProposals':
      return import('../QuotationProposals/QuotationProposals');
    case 'QuotationProposalsAddons':
      return import(
        '../QuotationProposals/components/QuotationProposalsAddons'
      );
    case 'QuotationProposalsOptions':
      return import(
        '../QuotationProposals/components/QuotationProposalsOptions'
      );
    case 'QuotationRecapDeclarations':
      return import('../QuotationRecapDeclarations/QuotationRecapDeclarations');
    default:
      return null;
  }
};
// eslint-disable-next-line react/function-component-definition
const BookingWidget: FC<BookingWidgetProps> = () => {
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const [activeStepIndex, setActiveStepIndex] = useState(1);
  const { showNotification } = useContext(NotificationContext);
  const { store, setCacheValue } = useContext(CacheDataContext);
  const { bookingDataResponse } = useContext(BookingDataContext);
  const [closedBanners, setClosedBanners] = useState<string[]>([]);
  const [banner, setBanner] = useState<Banner>(null!);
  const { t } = useTranslation();
  const { setIsLoading } = useContext(LoadingContext);
  const { appConfiguration, updateConfiguration } = useContext(
    AppConfigurationContext,
  );
  const { bookingSteps, update, setCurrentStep } = useContext(BookingContext);
  const { totalPrice } = usePriceSummaryLoading();
  const { bookingConfig, error } = useFetchBookingConfig(setIsLoading);

  const activeStep =
    bookingConfig?.[BookingConfigEnum.BookingSteps]?.[activeStepIndex];
  const isSummaryVisible = useSummaryVisible(activeStep);
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { setGoToStep } = useContext(StepperContext);
  const isTransparentBg: string | null = getUrlSearchParam(
    'transparent',
    urlParams,
  );
  const homeMedical = useMemo(
    () => getUrlSearchParam('homeMedical', urlParams),
    [urlParams],
  );
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const isMobile = useMediaQuery('(max-width:600px)');
  const isCardUX = false;
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const whiteLabel = isWhitelabel(bookingConfigData);

  const getInitialCustomersData =
    async (): Promise<BookingMeResponse | null> => {
      try {
        const url = `${apiCM360Url}/booking/me`;
        const customerDataRes: AxiosResponse = await API.get(url);
        if (customerDataRes.status !== 200) {
          showNotification('unexpectedError', 'error', false);
        }
        return customerDataRes.data;
      } catch (err) {
        errorService.log('getInitialCustomersData', err);
        localStorage.removeItem('token');
        return null;
      }
    };

  const [ActiveComponent, setActiveComponent] =
    useState<React.ComponentType<any> | null>(null);
  const loadComponent = async (step?: number): Promise<void> => {
    const stepKey =
      step !== null && step !== undefined ? step : activeStepIndex;
    if (bookingConfig) {
      const filteredObject = bookingConfig[
        BookingConfigEnum.BookingSteps
      ].filter(
        (bookingConfigItem) =>
          bookingConfigItem[BookingStepEnum.KeyName] ===
          bookingSteps[BookingStepValueEnum.CurrentStep],
      );
      const componentName =
        filteredObject.length > 0 && !step
          ? filteredObject[0][BookingStepEnum.Component]
          : (
              bookingConfig[BookingConfigEnum.BookingSteps]?.[
                stepKey
              ] as BookingStep
            ).component;
      if (!isCardUX && componentName === 'QuotationForm') {
        const QuotationFormWeb = (
          await import('../QuotationFormWeb/QuotationFormWeb')
        ).default;
        setActiveComponent(() => QuotationFormWeb);
      } else {
        const componentModule = await getComponent(componentName);
        if (componentModule) {
          setActiveComponent(() => componentModule.default);
        }
      }
    }
  };

  useEffect((): void => {
    loadComponent();
  }, [isCardUX]);

  useEffect((): void => {
    if (!bookingConfig) return;

    sendGTMEventPageChange({
      stepName:
        bookingConfig[BookingConfigEnum.BookingSteps][activeStepIndex][
          BookingStepEnum.KeyName
        ],
      bookingContext: bookingSteps,
      totalPrice,
      bookingConfig,
      businessUnit:
        bookingDataResponse?.[BookingManagementEnum.BookingData]?.[
          BookingDataEnum.BusinessUnitId
        ] || '',
    });
    const currentStep = bookingConfig[BookingConfigEnum.BookingSteps][
      activeStepIndex
    ][BookingStepEnum.KeyName] as BookingStepValueEnum;

    setCurrentStep(currentStep);
  }, [activeStepIndex, bookingConfig]);

  const getApplicationConfig = async (): Promise<void> => {
    try {
      const timestamp = Date.now();
      const configUrl = `/config/config_default.json?timestamp=${timestamp}`;
      const response = await fetch(configUrl);
      if (!response.ok) {
        showNotification('unexpectedError', 'error', false);
      }
      const fetchedData = await response.json();
      updateConfiguration(fetchedData.data);
      return await fetchedData.data;
    } catch (e) {
      return showNotification('unexpectedError', 'error', false);
    }
  };

  const checkBanner = (step: number): void => {
    const storedClosedBanners = localStorage.getItem('closedBanners');
    if (storedClosedBanners) {
      setClosedBanners(JSON.parse(storedClosedBanners));
    }
    if (bookingConfigData.bookingSteps[step][BookingStepEnum.Banner]) {
      setBanner(
        bookingConfigData.bookingSteps[step][BookingStepEnum.Banner] as Banner,
      );
    }
  };

  const updateInitialCustomersData = async (): Promise<void> => {
    let customerResponse: BookingMeResponse | undefined | null;
    if (apiCM360Url) {
      customerResponse = await getInitialCustomersData();
    }
    if (customerResponse && !Array.isArray(customerResponse)) {
      const customer: Customer =
        customerResponse[BookingMeResponseEnum.Customer];
      const companions: Companion[] =
        customerResponse[BookingMeResponseEnum.Companions];
      const companionsAge: string[] = [];

      companionsAge.push(
        differenceInYears(
          new Date(),
          new Date(customer[CustomerEnum.DateOfBirth]),
        ).toString(),
      );
      companions.map((companion) =>
        companionsAge.push(
          differenceInYears(
            new Date(),
            new Date(companion[CompanionEnum.DateOfBirth]),
          ).toString(),
        ),
      );
      if (bookingSteps.currentStep === 'quotationForm') {
        update(
          companionsAge,
          BookingStepValueEnum.QuotationForm,
          BookingSubStepValueEnum.TravellersAge,
        );
      }

      update(
        prepareInitInformationPersonal(customer),
        BookingStepValueEnum.QuotationInformation,
        BookingSubStepValueEnum.InformationPersonal,
      );

      const travellersAge =
        bookingSteps[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.TravellersAge
        ];
      if (
        bookingSteps.currentStep === 'quotationProposals' &&
        travellersAge.length > 1
      ) {
        update(
          prepareInitInformationTravellers(
            companions.slice(0, travellersAge.length - 1),
          ),
          BookingStepValueEnum.QuotationInformation,
          BookingSubStepValueEnum.InformationTravellers,
        );
      }
    }
  };

  const handleNext = useCallback(
    (step?: number): void => {
      if (typeof step === 'number') {
        loadComponent(step);
        setActiveStepIndex(step);
        window.scrollTo(0, 0);
        window.parent.postMessage({ type: 'SCROLL_TO_TOP' }, '*');
        checkBanner(activeStepIndex);
        return;
      }
      if (
        bookingConfig &&
        activeStepIndex <
          bookingConfig[BookingConfigEnum.BookingSteps].length - 1
      ) {
        loadComponent(activeStepIndex + 1);
        checkBanner(activeStepIndex + 1);
        setActiveStepIndex((prevStep) => prevStep + 1);
        window.scrollTo(0, 0);
        window.parent.postMessage({ type: 'SCROLL_TO_TOP' }, '*');
      }
    },
    [activeStepIndex, bookingConfig, checkBanner, loadComponent],
  );

  const setResumedStep = (step: string): void => {
    const stepIndex = bookingConfigData.bookingSteps.findIndex(
      (s) => s.keyName === step,
    );
    if (stepIndex > -1 && activeStepIndex !== stepIndex) {
      if (stepIndex === 0 && !isAgent()) {
        setActiveStepIndex(1);
      } else if (homeMedical) {
        setActiveStepIndex(BookingStepIndex.QuotationInformationForm);
        loadComponent(BookingStepIndex.QuotationInformationForm);
      } else {
        setActiveStepIndex(stepIndex);
        loadComponent(stepIndex);
      }
    }
  };

  const { resumeBooking } = useBookingResume({ setResumedStep });

  useEffect((): void => {
    if (bookingConfig) {
      loadComponent();
    }
    if (isAuthCustomer()) {
      updateInitialCustomersData();
    }

    if (!appConfiguration) {
      getApplicationConfig();
    }

    resumeBooking();

    // Used on IGO website
    if (isTransparentBg) {
      document.body.classList.add('bg-transparent');
    } else {
      document.body.classList.remove('bg-transparent');
    }

    if (bookingConfigData.bookingSteps.length > 0) {
      checkBanner(activeStepIndex);
    }

    if (bookingConfigData[BookingConfigEnum.Locale]) {
      i18n.changeLanguage(bookingConfigData[BookingConfigEnum.Locale]);
    }
  }, [bookingConfigData]);

  const closeBanner = (bannerId: string): void => {
    const updatedClosedBanners = [...closedBanners, bannerId];
    setClosedBanners(updatedClosedBanners);
    localStorage.setItem('closedBanners', JSON.stringify(updatedClosedBanners));
  };

  const handlePrevious = (stepIndex: number = activeStepIndex - 1): void => {
    loadComponent(stepIndex);
    checkBanner(stepIndex);
    setActiveStepIndex(stepIndex);
    window.scrollTo(0, 0);
    window.parent.postMessage({ type: 'SCROLL_TO_TOP' }, '*');
  };

  useEffect((): void => {
    setGoToStep(handlePrevious);
    setCacheValue('currentStep', activeStepIndex);
  }, [activeStepIndex]);

  useEffect(() => {
    subscribe$(AppEvents.ON_NAVIGATION_NEXT, handleNext);
    return () => {
      unsubscribe$(AppEvents.ON_NAVIGATION_NEXT, handleNext);
    };
  }, [handleNext]);

  if (error) {
    return (
      <Alert className="error-loading" severity="error">
        {t('COMMON.errorLoading')}
      </Alert>
    );
  }
  if (!bookingConfig) {
    return null;
  }

  if (!ActiveComponent) {
    return <SpinnerStatic />;
  }

  return (
    <Container className="main-container" data-testid="BookingWidget">
      {banner && !closedBanners.includes(banner[BannerEnum.Id]) && (
        <div className="banner">
          <div className="banner__title">{banner[BannerEnum.Content]}</div>
          <div className="banner__close">
            <HighlightOffIcon
              className="close-icon"
              onClick={() => closeBanner(banner[BannerEnum.Id])}
            />
          </div>
        </div>
      )}

      {bookingConfig[BookingConfigEnum.BookingSteps].length > 0 && (
        <>
          {whiteLabel && !isMobile && activeStepIndex === 1 && (
            <Box className="progress-stepper-container" sx={{ mt: 2, mb: 3 }}>
              <ProgressStepper keyName="quotationForm" />
            </Box>
          )}
          <div className="booking-container">
            {isMobile && (
              <div>
                {whiteLabel && <ProgressStepperMobile />}
                <OpenQuoteSummary
                  activeStep={
                    bookingConfig[BookingConfigEnum.BookingSteps][
                      activeStepIndex
                    ] as BookingStep
                  }
                />
              </div>
            )}
            <div
              className={`booking-component ${
                isSummaryVisible && isMobile ? 'with-quote' : ''
              }`}
              style={{
                ...(whiteLabel &&
                  activeStepIndex === 1 &&
                  !isMobile && {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }),
              }}
            >
              <div style={{ flex: '0 0 65%' }}>
                <WhiteLabelTitle />
                <ActiveComponent
                  bookingConfig={bookingConfig}
                  activeStepConfig={activeStep}
                  keyName={
                    bookingConfig[BookingConfigEnum.BookingSteps][
                      activeStepIndex
                    ][BookingStepEnum.KeyName]
                  }
                  cards={
                    bookingConfig[BookingConfigEnum.BookingSteps][
                      activeStepIndex
                    ][BookingStepEnum.Cards]
                  }
                  nextStep={handleNext}
                  previousStep={handlePrevious}
                />
              </div>
              <div style={{ flex: '0 0 35%' }}>
                {whiteLabel && activeStepIndex === 1 && !isMobile && (
                  <div className="whitelabel-header">
                    {t('QUOTATIONINFORMATIONFORM.travelInsurance')}
                  </div>
                )}
              </div>
            </div>
            {PanelRightVisibleForComponent.includes(
              (
                bookingConfig[BookingConfigEnum.BookingSteps][
                  activeStepIndex
                ] as BookingStep
              )[BookingStepEnum.Component],
            ) && (
              <Suspense fallback={<div>Loading...</div>}>
                <PanelRight
                  nextStep={handleNext}
                  activeStep={
                    bookingConfig[BookingConfigEnum.BookingSteps][
                      activeStepIndex
                    ] as BookingStep
                  }
                />
              </Suspense>
            )}
            {store.mobileSummaryVisible && <QuotationSummaryMobileBackdrop />}
          </div>
        </>
      )}
    </Container>
  );
};

export default BookingWidget;
