import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { Notification } from './Notification';

export type NotificationSeverity = 'success' | 'warning' | 'error';

export interface NotificationContextProps {
  open: boolean;
  message: string;
  severity: NotificationSeverity;
  translationArg?: string;
  showNotification: (
    message: string,
    severity: NotificationSeverity,
    skipTranslation: boolean,
    translationArg?: string,
  ) => void;
  hideNotification: () => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  open: false,
  message: '',
  severity: 'success',
  showNotification: (): void => undefined,
  hideNotification: (): void => undefined,
});

interface NotificationProviderProps {
  children: ReactNode; // Update the prop type to ReactNode
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [skipTranslation, setSkipTranslation] = useState(false);
  const [severity, setSeverity] = useState<NotificationSeverity>('success');
  const [translationArg, setTranslationArg] = useState<string | undefined>(
    undefined,
  );

  const showNotification = (
    messageContent: string,
    severityType: NotificationSeverity,
    skipTranslationValue: boolean,
    translationArgValue?: string,
  ): void => {
    setOpen(true);
    setMessage(messageContent);
    setSeverity(severityType);
    setSkipTranslation(skipTranslationValue);
    setTranslationArg(translationArgValue);
  };

  const hideNotification = (): void => {
    setOpen(false);
  };

  const contextValue = useMemo(
    () => ({
      open,
      message,
      severity,
      translationArg,
      skipTranslation,
      showNotification,
      hideNotification,
    }),
    [open, message, severity, skipTranslation, translationArg],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <Notification
        open={open}
        message={message}
        severity={severity}
        skipTranslation={skipTranslation}
        translationArg={translationArg}
        onClose={hideNotification}
      />
    </NotificationContext.Provider>
  );
};
